/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: camelCaseSensitive (https://sketchfab.com/camelCaseSensitive)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/lowpoly-earth-5f6ea1111fda4cf6a7b36cf4ce200d1b
Title: Lowpoly Earth
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { a, animated } from '@react-spring/three'

import earthScene from '../assets/3d/lowpoly_earth.glb';

const Earth = (props) => {
  const earthRef = useRef();
  const { nodes, materials } = useGLTF(earthScene)
  return (
    <a.group {...props} dispose={null}>
      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials.Material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_4.geometry}
          material={materials['Material.001']}
        />
      </group>
    </a.group>
  )
}

export default Earth;